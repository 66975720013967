/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: "Flaticon";
	src: url("../../assets/fonts/Flaticon.eot");
	src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../../assets/fonts/Flaticon.woff2") format("woff2"),
	url("../../assets/fonts/Flaticon.woff") format("woff"),
	url("../../assets/fonts/Flaticon.ttf") format("truetype"),
	url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-arrows:before { content: "\f100"; }
.flaticon-diet:before { content: "\f101"; }
.flaticon-right-arrow:before { content: "\f102"; }
.flaticon-vitamin-c:before { content: "\f103"; }
.flaticon-heart-rate-monitor:before { content: "\f104"; }
.flaticon-yoga:before { content: "\f105"; }
.flaticon-yoga-1:before { content: "\f106"; }
.flaticon-lotus:before { content: "\f107"; }
.flaticon-yoga-2:before { content: "\f108"; }
.flaticon-strawberry:before { content: "\f109"; }
.flaticon-tomato:before { content: "\f10a"; }
.flaticon-check:before { content: "\f10b"; }
.flaticon-healthy-food:before { content: "\f10c"; }
.flaticon-pineapple:before { content: "\f10d"; }
.flaticon-right-quote:before { content: "\f10e"; }
.flaticon-analytics:before { content: "\f10f"; }
.flaticon-anywhere:before { content: "\f110"; }
.flaticon-graduated:before { content: "\f111"; }
.flaticon-self-improvement:before { content: "\f112"; }
.flaticon-launch:before { content: "\f113"; }
.flaticon-padlock:before { content: "\f114"; }
.flaticon-self-growth:before { content: "\f115"; }
.flaticon-clock:before { content: "\f116"; }
.flaticon-ebook:before { content: "\f117"; }
.flaticon-factory:before { content: "\f118"; }
.flaticon-lcd:before { content: "\f119"; }
.flaticon-shield:before { content: "\f11a"; }
.flaticon-worldwide:before { content: "\f11b"; }
.flaticon-checkmark:before { content: "\f11c"; }
.flaticon-login:before { content: "\f11d"; }
.flaticon-credit-card:before { content: "\f11e"; }
.flaticon-verify:before { content: "\f11f"; }